import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

import MainLayout from "../../componentsTailwind/MainLayout";
import WhyMND from "../../components/whyMND";

import { Container, Flex, Wrapper, Box } from "../../style/Grid";
import { Heading } from "../../style/Heading";

import config from "../../configs";
import { FAQItem } from "../../components/FAQ/FAQItem";
import { contentData } from "../../pages/prepis-energii-elektriny-plynu";
import OmnisSituationsList from "../../components/OmnisSituation/OmnisSituationsList";
import GiveEnergy from "../../components/GiveEnergy/GiveEnergy";
import ContactForm from "../../components/contactForm";
import NewsListContainer from "../../components/News/NewsListContainer";
import { Button } from "../../style/Button";
import FixedProductsBanner from "../../componentsTailwind/Banners/FixedProductsBanner";
import { CONTRACT_SOURCE_ID } from "../../constants";
import QuestinsRelatedToIncreasePricesBanner from "../../components/QuestionsRelatedToIncreasePricesBanner";
import SolarPanelAndHeatPumpCTA from "../../componentsTailwind/SolarPanelAndHeatPumpCTA/SolarPanelAndHeatPumpCTA";
import RedemptionSolarEnergyCTA from "../../componentsTailwind/RedemptionSolarEnergyCTA/RedemptionSolarEnergyCTA";
import { SavingsCalculation } from "../../components/SavingsCalculation/SavingsCalculation";
import PhoneContactFormWrapper from "../../components/LeadForms/PhoneContactFormWrapper";

export default ({ data, pageContext }) =>
{
  const articleIds = pageContext.articleIds;
  const newsEdges = data.allNews.edges;
  const offersData = data.mnd.hp.data.attributes.fixedProducts;
  const bannerData = data.mnd.hp.data.attributes.pumpJackBanner;
  const questionArticles = data.mnd.hp.data.attributes.questionArticles;
  const fixedProductsBanner = data.mnd.hp.data.attributes.fixedProductsBanner;
  const SEO = data.mnd.hp.data.attributes.SEO;

  const webCalculator = data.mnd.webCalculator.data.attributes;

  const newsNodes = articleIds.map(articleId => newsEdges.find(newsEdge => newsEdge.node.newsId === articleId)).filter(Boolean);

  const [consumption, setConsumption] = useState(null);
  const [commodity, setCommodity] = useState(null);
  const [consumptionHighTariff, setConsumptionHighTariff] = useState(null);

  const onResultChange = ({
    consumption,
    commodity,
    consumptionLowTariff,
    consumptionHighTariff,
  }) =>
  {
    setCommodity(commodity);
    setConsumption(consumption);

    if (consumptionLowTariff)
    {
      setConsumption(consumptionLowTariff);
    }
    if (consumptionHighTariff)
    {
      setConsumptionHighTariff(consumptionHighTariff);
    }
  }
  const offers = data.mnd.offer.data.attributes.offers.filter(offer => !offer.isBonus);

  return (
    <MainLayout>
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="keywords" content={SEO.keywords} />
        <meta name="description" content={SEO.description} />
        <meta name="og:description" content={SEO.description} />
        <meta property="og:image" content={`${config.serverUrl}/images/og-image-hp.png`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Homepage', section: 'Gas | Electricity' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/"></link>
      </Helmet>
      <FixedProductsBanner
        title={bannerData.title}
        text={bannerData.text}
        buttonTitle={bannerData.buttonTitle}
        buttonLink={bannerData.buttonLink}
        offers={offers}
        textBottom={offersData.textBottom}
        textList={offersData.textList}
        bottomLinkList={offersData.bottomLinkList}
        fixedProductsBanner={fixedProductsBanner}
      />
      <div>
        <WhyMND bg={"white.100"} pb={5} />
      </div>

      <div className="bg-resource-green-100" id="kalkulacka">
        <SavingsCalculation offers={offers} data={webCalculator} onResultChange={onResultChange} analyticsData={{ section: "Homepage" }} />
      </div>

      <RedemptionSolarEnergyCTA />

      <SolarPanelAndHeatPumpCTA showSolarPanel={true} showHeatPump={true} className={"bg-resource-green-100 text-exploratory-green-400"} />

      <OmnisSituationsList showScrollToContact={false} className={"white.100"} />

      <QuestinsRelatedToIncreasePricesBanner className={"bg-resource-green-100"} articles={questionArticles} />

      {/*{!isEmpty(data.mnd.areYouCustomer.data.attributes) && (*/}
      {/*  <div className={"py-14"}>*/}
      {/*    <div className={"container mx-auto max-w-screen-xl px-4"}>*/}
      {/*      <AreYouMNDCustomer queryData={data.mnd.areYouCustomer.data.attributes} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      <Box className="white.100" py={"3rem"}>
        <div className={"container mx-auto max-w-screen-lg px-4"}>
          <h3 className="text-H4 mb-5 md:mb-10 uppercase text-exploratory-green-400" dangerouslySetInnerHTML={{ __html: contentData.FAQSection.title }} />
          {contentData.FAQSection.FAQs.map(faq => (
            <FAQItem key={faq.question} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </Box>

      <Wrapper
        position={"relative"}
        background={[
          `#ECE6E3 url(${require("../../images/banner/darkovy-image-mobile.jpg").default}) bottom center / 100% auto no-repeat`,
          `#ECE6E3 url(${require("../../images/banner/darkovy-image.jpg").default}) bottom center / auto 400px no-repeat`,
        ]}
      >
        <Container className={"max-w-screen-lg"} px={0}>
          <Box className="w-full sm:w-[50%] px-5" alignSelf={"flex-start"}>
            <GiveEnergy backgroundColor={"white.100"} left={0} />
          </Box>
        </Container>
      </Wrapper>

      <PhoneContactFormWrapper>
        <ContactForm
          enableCallbackTime={true}
          enableEmail={false}
          type="PAGE_HP"
          commodity={commodity}
          consumption={consumption * 1000}
          consumptionHighTariff={consumptionHighTariff * 1000}
          analytics={{
            type: "Lead",
            text: "Ozveme se vám",
            section: "Homepage",
            sourceId: CONTRACT_SOURCE_ID,
          }}
        />
      </PhoneContactFormWrapper>

      <div className={"max-w-screen-xl mx-auto px-4 py-10 sm:py-14"}>
        <div className="flex-col text-left sm:text-center mb-10">
          <h2 className={"text-H3 sm:text-H2 mb-0 bold text-exploratory-green-400"}>Novinky nejen z MND</h2>
        </div>

        <NewsListContainer
          currentPage={0}
          news={newsNodes}
          nextPages={[null]}
          totalNews={3}
          newsPageCount={0}
          pages={[0]}
          hidePaginator={true}
          showDescription={false}
        />

        <Flex justifyContent="center" pt={4} width={"100%"}>
          <Button flex={["0 1 165px", "0 0 205px", "0 0 213px"]} maxWidth={["165px", "none"]} variant={"primary"} as={Link} to={`/novinky`}>
            Další novinky
          </Button>
        </Flex>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query HomePageDPIQuery($articleIds: [Int]) {
    allNews(filter: { newsId: { in: $articleIds } }) {
      edges {
        node {
          releaseDate {
            date
          }
          newsId
          id
          name
          slug
          perex
          promolink
          calculatorType
          sidebarCalculatorType
          content
          type
          descriptionImage
        }
      }
    }

    mnd {
      #      areYouCustomer {
      #        data {
      #          attributes {
      #            title
      #            myMND {
      #              title
      #              text
      #              ctaText
      #              ctaLink
      #            }
      #            customerCare {
      #              title
      #              text
      #              ctaText
      #              ctaLink
      #            }
      #          }
      #        }
      #      }
      hp {
        data {
          attributes {
            SEO {
              title
              description
              keywords
            }
            articles {
              articleID
            }
            pumpJackBanner {
              title
              text
              buttonTitle
              buttonLink
            }
            HowTransitionWork {
              title
              faqITems {
                title
                text
              }
            }
            fixedProducts {
              textBottom
              bottomLinkList {
                content
                icon
              }
            }
            questionArticles {
              title
              linkText
              href
              icon
            }
            fixedProductsBanner {
              largeImage {
                data {
                  attributes {
                    url
                  }
                }
              }
              smallImage {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }

      ...WebCalculator

      ...FullOffers
    }
  }
`;
